export default function Footer() {
  return (
    <>
      <footer className="relative">
        <div className="container max-w-7xl mx-auto px-4">
          <hr className="my-6 border-purple-700" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-purple-700 font-medium py-1">
                <a
                  href="https://wa.me/message/U6O2XAZSAVZSH1"
                  target="_blank"
                  rel="noreferrer"
                >
                  &copy; 2021 DUO/GR Exclusive Agent In Middle East (M.ALATTAR)
                  <br />
                  +961 81 357 217
                </a>
              </div>
              <div className="m-5 bg-purple-700 text-gray-200 rounded-lg">
                <a
                  href="https://itzkassem.github.io/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Coded By Kassem Kanaan
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
