import { Link } from "react-router-dom";
import React, { useContext } from "react";
import { Button, WindmillContext } from "@windmill/react-ui";
import { FaWhatsapp, FaMoon, FaSun } from "react-icons/fa";
function TopNav() {
  const { mode, toggleMode } = useContext(WindmillContext);
  return (
    <div className="top-0 z-50">
      <nav className="flex items-center justify-between px-6 py-2 rounded-b-lg bg-gray-50 dark:bg-gray-800 shadow-lg">
        <Link to="/">
          <p className="text-purple-600 dark:text-purple-700 logo-font">DUO</p>
        </Link>
        <div className="relative space-x-4">
          <a
            href="https://wa.me/message/U6O2XAZSAVZSH1"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              icon={FaWhatsapp}
              aria-label="Like"
              className="fill-current text-gray-50"
            />
          </a>
          <Button
            className="rounded-md focus:outline-none focus:shadow-outline-purple"
            onClick={toggleMode}
            layout="outline"
            aria-label="Toggle color mode"
          >
            {mode === "dark" ? (
              <FaSun
                className="w-5 h-5 fill-current text-gray-50 "
                aria-hidden="true"
              />
            ) : (
              <FaMoon
                className="w-5 h-5 fill-current text-gray-600"
                aria-hidden="true"
              />
            )}
          </Button>
        </div>
      </nav>
    </div>
  );
}

export default TopNav;
