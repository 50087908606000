import { Link } from "react-router-dom";
import { Button } from "@windmill/react-ui";
function DownNav() {
  return (
    <nav className="sticky bottom-5 mr-5 ml-5 z-50 flex items-center justify-center px-6 py-2 rounded-lg bg-purple-700  shadow-lg">
      <ul className="flex space-x-4">
        <li>
          <Link to="/">
            <Button>Home</Button>
          </Link>
        </li>
        <li>
          <Link to="/products/accesories">
            <Button>Accessories</Button>
          </Link>
        </li>
        <li>
          <Link to="/products/parts">
            <Button>Parts</Button>
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default DownNav;
