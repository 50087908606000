import { lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import TopNav from "./components/TopNav";
import Footer from "./components/Footer";
import DownNav from "./components/DownNav";
const Upload = lazy(() => import("./pages/Upload"));
const Home = lazy(() => import("./pages/Home"));
const Products = lazy(() => import("./pages/Products"));
const Item = lazy(() => import("./pages/Item"));

function Router() {
  return (
    <Switch>
      <Route exact path="/" component={() => <Home />} />
      <Route
        exact
        path="/products/parts*"
        component={() => (
          <Products title="SpareParts" search={false} category="parts" />
        )}
      />
      <Route
        exact
        path="/products/accesories*"
        component={() => (
          <Products
            title="Mobile Accessories"
            search={false}
            category="accessories"
          />
        )}
      />
      <Route exact path="/item/:pId" component={() => <Item />} />
      <Route exact path="/item/:pId/edit" component={() => <Upload />} />
      <Route exact path="/upload" component={() => <Upload />} />
      <Redirect from="*" to="/" />
    </Switch>
  );
}

function App() {
  return (
    <div className="bg-purple-50 dark:bg-gray-900">
      <TopNav />
      <div className="min-h-screen m-5 md:my-5">
        <Router />
      </div>
      <div className="mt-5" />
      <DownNav />
      <Footer />
    </div>
  );
}

export default App;
